
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import { get } from '../../helpers/Api';
import sha1 from 'sha1';
import { adminDomain } from  '../../constants/defaultValues';

import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    USER
} from '../actions';

import {
    loginUserSuccess,
    registerUserSuccess
} from './actions';

const resultGet = async (method,params) =>
    await get(method,params)
        .then(authUser => authUser)
        .catch(error => error);



function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(resultGet,'login',{usuario:email,clave:sha1(password)});
        if (loginUser.status=="200") {
            console.log(loginUser);
            if(loginUser.redirect_admin){
                localStorage.setItem('user_id', loginUser.jwt);
                localStorage.setItem('redirect_admin', loginUser.redirect_admin);
                console.log("Setio el redirect ");
                window.location.replace(adminDomain+"/rest_login/login?token="+loginUser.jwt+"&modulo=inicio");
            }else{
                localStorage.setItem('user_id', loginUser.jwt);
                localStorage.setItem('redirect_admin', loginUser.redirect_admin);
                yield put(loginUserSuccess(loginUser));
                history.push('/');
                console.log("------user-----")
                const user = yield call(resultGet,'userconfig',{token:loginUser.jwt});
                localStorage.setItem('bodega_id', user.bodega_id)
                localStorage.setItem('url_imprime', user.url_imprime)
            }
        } else {
            console.log('login failed :', loginUser.error)
        }
    } catch (error) {
        console.log('login error : ', error)
    }
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        console.log('register error : ', error)
    }
}



const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({payload}) {
    const { history } = payload
    try {
        yield call(logoutAsync,history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('bodega_id');
        localStorage.removeItem("url_imprime");

    } catch (error) {
    }
}



export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser)
    ]);
}